<template>
  <div>
    <!--
    <div class="parallax">
    </div>
    -->
    <!-- CAROUSEL-->
    <div class="parallax" v-if="windowWidth>767"> 

    </div>

    

     <div  v-if="windowWidth<768">
      <b-row>
        <b-col>
          <b-img fluid src="https://teotihuacan.gob.mx/files/imagenes/teotihuacanp.jpg"></b-img>
        </b-col>
      </b-row>
    </div> 

    <!-- <b-row>
      <b-col cols="12" >
        <div>
          
          <b-carousel indicators :interval="5000" controls >
            <b-carousel-slide                
                v-if="resources!=null"
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row> -->

    <h1 class="titles mt-5">

<!--       <span style="color: #d82125">B</span>
      <span style="color: #d02175">i</span>
      <span style="color: #f6c921">e</span>
      <span style="color: #d02175">n</span>
      <span style="color: #4d944a">v</span>
      <span style="color: #07aaba">e</span>
      <span style="color: #d82125">n</span>
      <span style="color: #d02175">i</span>
      <span style="color: #f6c921">d</span>
      <span style="color: #d02175">o</span>
      <span style="color: #07aaba">s</span> -->
      
    </h1>
    <!-- 
        #d82125   T
        #d02175   E
        #f6c921   P
        #d02175   E
        #4d944a   T
        #07aaba   L
        #d82125   A
        #d02175   O
        #f6c921   X
        #d02175   T
        #07aaba   O
        #4d944a   C
    -->
    
   

    <!-- <b-row>
      <b-col cols="12" v-if="resources!=null">
        <div id="example" style="">
          <carousel-3d
            :autoplay="true"
            :autoplayTimeout="3000"
            :autoplayHoverPause="true"
            height="400"
            width="600"
            border="0"
          >
          
            <slide style="background-color:white;"
             v-for="(aviso,i) in resources.avisos" :index="i" :key="i">
              <b-row align="center">
                <b-col>
                  <img :src="aviso.path_img">
                  <a class="mt-2" v-if="aviso.url!=null && aviso.url!=''" :href="aviso.url" target="_blank">
                    <h6 style="color:gray;">Consultar</h6>
                  </a>
                </b-col>
              </b-row>
            </slide>
          
          </carousel-3d>
          
        </div>
      </b-col>
    </b-row> -->

    <b-row align-h="center" align-v="center">
      <b-col cols="11" md="10" lg="10" xl="8" >
        <b-carousel
          class="c-shadow-sm"
          controls
          indicators
          img-width="1024"
          img-height="480"
        >

        <a href="" target="_blank">
          <b-carousel-slide  img-src="https://teotihuacan.gob.mx/files/avisos/portadateotihuacan.jpg">
          </b-carousel-slide>
        </a>
        
        <!-- <a href="http://siopinna.edomex.gob.mx/" target="_blank">
          <b-carousel-slide  img-src="https://teotihuacan.gob.mx/files/imagenes/siopina.png">
          </b-carousel-slide>
        </a> -->
          <!-- <b-carousel-slide
           
            img-src="https://scontent.fmex10-3.fna.fbcdn.net/v/t39.30808-6/290047480_157354320173240_2128150449936355499_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeE0HX5dCE4psPu_gLQGzRsZMYuYsiX4h3kxi5iyJfiHeR1W4eJ0FWSHKec_baLQidA&_nc_ohc=wnxBvAtZoTUAX8lTIGu&_nc_ht=scontent.fmex10-3.fna&oh=00_AT_liH3r7jgyewAEw2qJlMGKdpRtAXuTKiYDu09NEUwifw&oe=62BA800B"
          ></b-carousel-slide>
          <b-carousel-slide
            
            img-src="https://scontent.fmex10-4.fna.fbcdn.net/v/t39.30808-6/289988010_157055886869750_8302681970667326089_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeEZxGpPpu2D7m3h29VMVDBByJoVJDBh2pvImhUkMGHam29zPWoHvvmqHlyZeIPamJo&_nc_ohc=mhAXqpFcmVQAX9zOn-Q&_nc_ht=scontent.fmex10-4.fna&oh=00_AT9RMJ7WqqsXGIDJVtOEQWCWXP8U0ugDO_bcAQDxg-Cc0g&oe=62BC5FEF"
          ></b-carousel-slide> 
          <b-carousel-slide
            
            img-src="https://scontent.fmex10-4.fna.fbcdn.net/v/t39.30808-6/289604492_156830856892253_2697903645438526270_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeEsrEO_1bcLvgT2W9YIk8eTzEVCwODtCafMRULA4O0Jp7K1S2NXZ1FXT6hJKYHVtfE&_nc_ohc=pzpg5tAV7skAX_9oBzg&_nc_ht=scontent.fmex10-4.fna&oh=00_AT-EUoZTeC57-Fu3DOIBRv3SrUcnydtgK0aiMOd0clIgeQ&oe=62BC00E8"
          ></b-carousel-slide> -->
        </b-carousel>
      </b-col>

      
    </b-row>
    <br><br><br><br>
<!-- 
    <b-row align-h="center" align-v="center">
      <b-col cols="11" md="10" lg="5" xl="4"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/descuentopredial.jpg"></b-img> </b-col>
      <b-col cols="11" md="10" lg="5" xl="4"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/descuentosagua.jpg"></b-img> </b-col>
    </b-row><br><br> -->
     <!-- <b-row align-h="center" align="center" class="mb-5">
      <b-col cols="9" md="5" lg="5" class="mb-5">
        <a href="https://teotihuacan.gob.mx/files/informe/2INFORME_2023_089.pdf" target="blank">
        <b-img  fluid alt="Responsive image" class="c-shadow-sm" src="https://teotihuacan.gob.mx/files/informe/2doinforme.png">
        </b-img>
        </a>
      </b-col>
     </b-row> -->

    <b-row align-h="center" align-v="center">
      <b-col  cols="11" md="10" lg="8" xl="8">
        <b-row>
          <b-col cols="11" md="10" lg="5" xl="4" class="mt-3"><a href="#" onclick="window.open('http://teotihuacan.gob.mx/files/avisos/BANCO FAMILIAR DE DATOS.pdf'); window.open('http://teotihuacan.gob.mx/files/avisos/tips.pdf');"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/BANCO-FAMILIAR-DE-DATOS.png"></b-img> </a></b-col>
          <b-col cols="11" md="10" lg="5" xl="4" class="mt-3"><a href="http://siopinna.edomex.gob.mx/" target="_blank"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/siopina.jpeg"></b-img></a></b-col>
          <b-col cols="11" md="10" lg="5" xl="4" class="mt-3"><a href="https://www.ieem.org.mx/consultaIJ2023/" target="_blank"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/consulta2023.png"></b-img></a></b-col>
          <b-col cols="11" md="10" lg="5" xl="4" class="mt-3"><a href="https://turismo.edomex.gob.mx/sites/turismo.edomex.gob.mx/files/files/resultados_C_PM.pdf?fbclid=IwAR1iKfFb3TzUOAffekHstAyH3y0xrrktfJVx0mzlzyxt39pjNkL4G6fYhs0" target="_blank"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/integracionc.jpg"></b-img></a></b-col>
          <b-col cols="11" md="10" lg="5" xl="4" class="mt-3"><a href="https://teotihuacan.gob.mx/files/avisos/FORMULARIO%20MUNICIPIOS.pdf" target="_blank"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/encuestapintegracion.png"></b-img></a></b-col>
          
          <!-- <b-col cols="11" md="10" lg="5" xl="4" class="mt-3"><a href="https://recaudacion.gobinet.mx/teotihuacan/portalcontribuyente" target="_blank"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/predialteo2024.jpg"></b-img></a></b-col>
          <b-col cols="11" md="10" lg="5" xl="4" class="mt-3"><a href="https://teotihuacan.gob.mx" target="_blank"><b-img fluid src="https://teotihuacan.gob.mx/files/avisos/aguateo2024.jpg"></b-img></a></b-col>
         -->
        </b-row>
      </b-col>
      
    </b-row><br><br><br><br>

    

    <br><br><br><br>
    <b-row align-h="center" align-v="center">
       <b-col cols="11" md="10" lg="5" xl="5" >
        <b-row>
          <b-col cols="12" class="mt-4 mb-5">
            <h1 class="titles " >
              <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Redes Sociales</span>
            </h1>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-container fluid 
              style="
                max-width: 550px;
                min-width: 100px;
                overflow-y: scroll;
                overflow-x: scroll;
                aling: center;
              "

              class="py-5 c-shadow-sm"
            
            >
              <iframe
                :src="resources.url_facebook"
                height="500"
                style="border: none; overflow: hidden; width:100%"
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                data-adapt-container-width="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                
              ></iframe>
            </b-container>
          </b-col>
        </b-row>
      </b-col> 
    </b-row>


<!--
    <br /><br />

    <b-row align-h="center">
      <b-col cols="12" class="mt-4 mb-5">
        <h1 class="titles " >
          <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Redes Sociales</span>
        </h1>
      </b-col>
    </b-row>

    <b-row align-h="center" v-if="resources!=null">
      <b-col cols="11" md="10" lg="4" xl="4"  class="mb-5" style="height:380px;">
        <div style="height:100%; width: 100%;">
          <b-embed
            class="c-shadow-sm"
            style="width:100%; height:380px;"
            type="iframe"
            :src="resources.url_video1"
          ></b-embed>
        </div>
      </b-col>
      <b-col cols="11" md="10" lg="4" xl="4" class="mb-5" style="height:380px;">
        <div style="height:100%; width: 100%;">
          <b-embed
            class="c-shadow-sm"
            style="width:100%; height:380px;"
            type="iframe"
            :src="resources.url_video2"
          ></b-embed>
        </div>
      </b-col>
     
    </b-row>-->

    <!-- IMAGE | CARD 
    <b-row align-h="center" align-v="center" class="mx-1">
      <b-col sm="12" md="6">
        <b-link to="whitepage">
          <img center src="@/assets/image.jpg" responsive class="img" />
        </b-link>
      </b-col>
      <b-col sm="12" md="6" class="text-center mt-3">
        <h1 class="titles">
          <span style="color: #ee207b">Bi</span>
          <span style="color: #0fabb6">en</span>
          <span style="color: #f68b1f">ve</span>
          <span style="color: #78c042">ni</span>
          <span style="color: #ee2e3a">dos</span>
        </h1>

        <b-card title="Card Title" class="mb-3">
          <b-card-text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <br /><br /><br /><br />-->

    <!-- CATALOGO DE TRAMITES -->
    
        <br>
        <br>
        <br>
        <br><br>
    <b-row>
        <!--
        <h1 class="titles">    
          <span style="color: #d82125">T</span>
          <span style="color: #d02175">r</span>
          <span style="color: #f6c921">á</span>
          <span style="color: #d02175">m</span>
          <span style="color: #4d944a">i</span>
          <span style="color: #07aaba">t</span>
          <span style="color: #d82125">e</span>
          <span style="color: #d02175">s</span>
          <span style="color: #f6c921"> E</span>
          <span style="color: #d02175">n</span>
          <span style="color: #07aaba"> L</span>
          <span style="color: #4d944a">í</span> 
          <span style="color: #d82125">n</span>
          <span style="color: #d02175">e</span>
          <span style="color: #f6c921">a</span>
           
        </h1>
        -->
        <h1 class="titles">
          <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Trámites En Línea</span>
        </h1>

    </b-row>


    <b-row align-h="center" align="center">
        <b-col cols="10" sm="8" md="10" lg="8" class="mt-5" >
          <b-row align-h="center" align="center">

            <b-col cols="10" sm="8" md="4" class="mt-5" >
              <div class="imagebackground1 c-shadow-sm" style="cursor:pointer;" @click="showPredial=true">
                <div class="redtransparent" >
                  <b-row >
                    <b-col cols="12" >
                      <b-icon style="color:white;" font-scale="6" icon="house-door"></b-icon>
                    </b-col>
                    <b-col cols="12" style="color:white;" ><h4>Impuesto Predial</h4>
                    </b-col>
                  </b-row>
                </div>               
              </div>                      
            </b-col>

            <b-col cols="10" sm="8" md="4" class="mt-5" >
              <div class="imagebackground2 c-shadow-sm" style="cursor:pointer;" @click="showFact=true">
                <div class="redtransparent">
                  <b-row >
                    <b-col cols="12" >
                      <b-icon style="color:white;" font-scale="6" icon="file-earmark-text"></b-icon>
                    </b-col>
                    <b-col cols="12" style="color:white;" ><h4>Facturación</h4>
                    </b-col>
                  </b-row>
                </div>               
              </div>                      
            </b-col>

            <b-col cols="10" sm="8" md="4" class="mt-5" >
              <a v-if="resources.url_tramites!=null && resources.url_tramites!=''" href="https://teotihuacan.gob.mx" target="_blank">
                <div class="imagebackground3 c-shadow-sm" style="cursor:pointer;" >
                  <div class="redtransparent">
                    <b-row >
                      <b-col cols="12" >
                        <b-icon style="color:white;" font-scale="6" icon="columns-gap"></b-icon>
                      </b-col>
                      <b-col cols="12" style="color:white;  text-decoration: none;" ><h4>Trámites En Línea</h4>
                      </b-col>
                    </b-row>
                  </div>               
                </div>                      
              </a>
            </b-col>
            
          </b-row>
        </b-col> 
    </b-row>

    <br /><br /><br /><br />
    <b-row class="mt-5">
      <b-col>
<!--         <h1 class="titles">

          <span style="color: #d82125">O</span>
          <span style="color: #d02175">r</span>
          <span style="color: #f6c921">g</span>
          <span style="color: #d02175">a</span>
          <span style="color: #4d944a">n</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">s</span>
          <span style="color: #d02175">m</span>
          <span style="color: #f6c921">o</span>
          <span style="color: #d02175">s</span>
          <span style="color: #07aaba"> A</span>
          <span style="color: #4d944a">u</span>

          <span style="color: #d82125">x</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">l</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">a</span>
          <span style="color: #07aaba">r</span>
          <span style="color: #d82125">e</span>
          <span style="color: #d02175">s</span>
          
        </h1> -->
        <h1 class="titles">
          <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Organismos Auxiliares</span>
        </h1>
        
        <br><br>
      </b-col>
    </b-row>
    <!-- CARDS-->
    <b-row align-v="center" align-h="center" align="center">

      <b-col cols="10" sm="7" md="4" lg="3" xl="2">
        <b-card style="margin-top:40px; margin-bottom:60px;  " class="c-shadow-sm"  >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/palacio.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row  style="min-height:60px;" class=" text-center" align-h="center" align-v="center">
            <b-col style=" margin-top:60px; min-height:60px;" cols="12">
              <h5><b>Imcufidet</b></h5>
              </b-col>
          </b-row>   
          <b-row><b-col class="mb-1"><b-button @click="$router.push('/imcufidet')" pill size="sm" variant="secondary">Conoce más <b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>
      <br>
       
      <b-col cols="10" sm="7" md="4" lg="3" xl="2">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; min-height:60px;" >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/family-room.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row  style="min-height:60px;" class=" text-center" align-h="center" align-v="center">
            <b-col style=" margin-top:60px; min-height:60px;" cols="12">
              <h5><b>DIF Municipal</b></h5>
              </b-col>
          </b-row>   
          <b-row><b-col class="mb-1"><b-button @click="$router.push('/dif')" pill size="sm" variant="secondary">Conoce más <b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col>
      <br>
      <b-col cols="10" sm="7" md="4" lg="3" xl="2">
        <b-card class=" c-shadow-sm" style="margin-top:40px; margin-bottom:60px; " >
          <b-row align="center" justify="center">
            <b-col>
            <div class="img-supposition-rounded">
              <div :style="iconBall"> 
                <img width="45px;" height="45px;" style="margin-top:25px;" :src="require('../assets/agua.png')" />
              </div>
            </div>
            </b-col>
          </b-row>
          <b-row  style="min-height:60px;" class=" text-center" align-h="center" align-v="center">
            <b-col style=" margin-top:60px; min-height:60px;" cols="12">
              <h5><b>Organismo de Agua</b></h5>
              </b-col>
          </b-row>   
          <b-row><b-col class="mb-1"><b-button @click="$router.push('/odeapast')" pill size="sm" variant="secondary">Conoce más <b-icon icon="arrow-right-circle-fill"/></b-button></b-col></b-row>
        </b-card>
      </b-col> 
    </b-row>

    <br /><br /><br /><br />


    <b-row align-v="center" align-h="center" align="center" class="pt-4 mx-1 mb-5">
      
        <h1 class="titles">
          <span style="color: #8b8c8c">Normatividad</span>
        </h1>
    </b-row>

    <b-row align-h="center" align="center" class="mb-5">
      <b-col cols="9" md="5" lg="3" class="mb-5">
        <a href="https://teotihuacan.gob.mx/files/bando/Bando Municipal 2023.pdf" target="blank">
        <b-img  fluid alt="Responsive image" class="c-shadow-sm" src="https://teotihuacan.gob.mx/files/bando/portadabando.png">
        </b-img>
        </a>
      </b-col>
      
      <b-col cols="9" md="5" lg="3" class="mb-5">
        <a href="https://teotihuacan.gob.mx/files/plandm/PDM%20TEOTIHUACAN.pdf" target="_blank">
        <b-img  fluid alt="Responsive image" class="c-shadow-sm" src="https://teotihuacan.gob.mx/files/plandm/pdm.png">
        </b-img>
        </a>
      </b-col>
    </b-row>


    <b-row align-h="center">
      <b-col cols="11" md="9" lg="9" class="mb-5">
        <a href="https://sesaemm.gob.mx/pea/difusion/ " target="_blank">
          <b-img  fluid alt="Responsive image" class="c-shadow-sm" src="https://teotihuacan.gob.mx/files/avisos/anticorrupcion.png">
          </b-img>
        </a>
      </b-col>
    </b-row>

    <!-- CARDS CONTACT -->
    <b-row align-v="center" align-h="center" align="center" class="pt-4 mx-1">
      <!-- <h1 class="titles">
          <span style="color: #d82125">N</span>
          <span style="color: #d02175">ú</span>
          <span style="color: #f6c921">m</span>
          <span style="color: #d02175">e</span>
          <span style="color: #4d944a">r</span>
          <span style="color: #07aaba">o</span>
          <span style="color: #d82125">s</span>
          <span style="color: #d02175"> d</span>
          <span style="color: #f6c921">e</span>
          <span style="color: #d02175"> E</span>
          <span style="color: #07aaba">m</span>
          <span style="color: #4d944a">e</span>

          <span style="color: #d82125">r</span>
          <span style="color: #d02175">g</span>
          <span style="color: #f6c921">e</span>
          <span style="color: #d02175">n</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">a</span>
          

        </h1> -->

        
    
    



    <h1 class="titles">
      <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Números de Emergencia</span>
    </h1>
  

    </b-row>
    <div style="background: linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(219,219,219,1) 0%, rgba(164,164,164,1) 100%); margin-top:250px;">
      <b-row align-v="center" align-h="center" align="center" class="pt-4 mx-1" v-if="resources!=null">
        <b-col cols="12" style="margin-top:-250px;">
          <b-link to="whitepage">
            <b-img
              center
              :src="resources.url_img_telefonos"
              style="max-height:500px"
            ></b-img>
          </b-link>
        </b-col>

        <b-col v-for="(numero, i) in resources.telefonos" :key="i" cols="10" sm="7" md="4" lg="3" xl="2" class="mt-4">
          <b-card class="c-shadow-sm" style="min-height:155px;">
            <b-icon
              :icon="numero.icono"
              font-scale="2"
              class="pointer"
              variant="danger"
            ></b-icon>
            <b-card-text>
              <b><p>{{numero.descripcion}}</p></b>
              <b-icon  icon="telephone-fill"></b-icon><span> {{numero.telefono}}</span>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <br><br>
    </div>

    <b-row no-gutters>
      <b-col >
        <div>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3158.9437227146705!2d-98.870197696651!3d19.685428653210906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1eaf79d82605b%3A0x526c0603a2e46106!2sJu%C3%A1rez%201%2C%20Teotihuacan%20Centro%2C%2055800%20San%20Juan%20Teotihuacan%20de%20Arista%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1656132595927!5m2!1ses!2smx" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </b-col>
    </b-row>

    
     <b-modal v-model="showPredial" size="lg" title="Tesorería En Línea" hide-footer>
       <b-row no-gutters>
         <b-col style="padding:0px; " >
           <b-embed
              type="iframe"
              src="https://recaudacion.gobinet.mx/teotihuacan/portalcontribuyente"
              allowfullscreen     
              style="width:100%; height:1000px;"
              >

            </b-embed>
         </b-col>
       </b-row>
    </b-modal>
    <b-modal v-model="showFact" size="lg" title="Tesorería En Línea" hide-footer>
      <b-row no-gutters>
        <b-col style="padding:0px; " >
        <b-embed
          type="iframe"
          src="https://recaudacion.gobinet.mx/teotihuacan/facturacion"
          allowfullscreen     
          style="width:100%; height:1000px;"
          >

        </b-embed>
        </b-col>
      </b-row>
      
    </b-modal>
    <b-modal v-model="showGobinet" size="lg" title="Trámites En Línea" hide-footer>
      <h1>En constucción ...</h1>
    </b-modal>

    



  </div>
</template>
<script>
  import Flipbook from "flipbook-vue";
  import axios from "axios";
  

  export default {
    components: {
      Flipbook,
    },
    mounted: function(){
      
      

      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/inicio.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });
      window.onresize = () => {
          this.windowWidth = window.innerWidth
      }
    },
    computed: {
      styleIconBall: function(){
        var style = this.iconBall;
        if(this.width <= 768){
          style=this.iconBallSm;
        }
        return style;
      },
      iconBall: function(){
        return " display: block; width: 100%; height: 100%; align-items: center; " +
        "background: linear-gradient(90deg, rgba(255,31,31,1) 0%, rgba(248,0,0,1) 100%);" +
        "border-radius: 50%; " +
        "box-shadow: inset -25px -25px 40px rgba(10,10,30,.4); " +
        "background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "position:absolute; left: 0px; top:0px; text-align:center; ";
      },
      pages: function () {
        
        let pages = [null,]//null primera pagina para que solo aparezca la portada

        for (let i = 1; i <= this.resources.numero_paginas_bando; i++) {
              let image_num = i.toString().padStart(4,0)        
              pages.push('/files/bando/'+this.resources.prename_paginas_bando+image_num+'.jpg')
        }
        return pages;
      },
    },
    data() {
      return {
        resources:null,
        showPredial:false,
        showFact:false,
        showGobinet:false,
        windowWidth: window.innerWidth,
      }
    }
    
  }
</script>

<style>
.flipbook .viewport {
    width: 90vw;
    height: 90vh;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

</style>